import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo';

const Seo = (props) => {
  const title = props?.share_title ? `${props?.share_title} | Walbeck Baseball Academy` : `Walbeck Baseball Academy | Hundreds of Baseball Training Videos Online`;

  const description = props?.share_description || `Baseball training videos created by 10-year MLB veteran and coach, Matt Walbeck. Drills and exercises to improve you hitting, pitching, catching, fielding, throwing, and more.`;

  const image = props?.share_image || `${process.env.NEXT_PUBLIC_URL}/share.jpg`;

  const type = props?.share_type || 'website'

  return (
    <>
      <NextSeo 
        title={title}
        description={description}
        openGraph={{
          title: title,
          description: description,
          images: [
            {
              url: image,
              width: 1200,
              height: 600,
            },
          ],
          url: process.env.NEXT_PUBLIC_URL,
          type: type,
          site_name: 'Walbeck Baseball Academy',
        }}
        twitter={{
          site: '@WalbeckWBA',
          cardType: 'summary_large_image',
        }}
  
      />

    </>
  );
}

export default Seo;