// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://e039470ab0ae4d5fbbbeb1f0cb6a263b@o417701.ingest.sentry.io/5853754',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.25,
  debug: false,
  beforeSend(event, hint) {
    if(event.exception) {
      // Sentry.showReportDialog({ eventId: event.event_id });
    }

    return event;
  }
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
