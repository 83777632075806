import { createContext, useContext, useEffect, useReducer } from 'react';

import { useUser } from 'services/user';
import { secondsToMinutes } from 'utils/string';
import { usePreferences } from './preferences';

export const PreviewContext = createContext();

let reducer = (state, action) => {
  switch (action.type) {
    case 'REDUCE_SECOND':
      if(state.seconds == 1) {
        return {...state, seconds: 0};
      }
      else {
        return {...state, seconds: state.seconds - 1};
      }
    case 'SET_SECONDS':
      return {...state, seconds: action.payload};
    case 'SET_EXPIRES':
      return {...state, expires: action.payload};
    case 'SET_NEWSLETTER':
      return {...state, newsletter_filled: action.payload};
    case 'SET_DISCOUNT_MODAL':
      return {...state, discount_modal: action.payload};
    case 'DISCOUNT_MODAL_CLOSE':
      let show_again_date = new Date();
      show_again_date.setDate(show_again_date.getDate() + 3);

      return {...state, discount_modal: {
        filled: false,
        show_32522: false,
        show_again_date: show_again_date,
      }};
    case 'DISCOUNT_MODAL_SIGNUP':
      return {...state, discount_modal: {filled: true, show_again_date: false}};
    case 'RESET':
      return initialState(action.payload);
    default:
      throw new Error();
  }
};

const initialState = ({seconds, frequency}) => {
  let expireTime = new Date();
  expireTime.setDate(expireTime.getDate()+frequency);

  const initial = {
    seconds: seconds,
    expires: expireTime,
    newsletter_filled: false,
    discount_modal: {
      filled: false,
      show_32522: true,
      show_again_date: new Date()
    }
  };
  localStorage.setItem("preview", JSON.stringify(initial));

  return initial;
}

export const PreviewContextProvider = (props) => {
  const { user } = useUser();
  const { preview_time, preview_frequency } = usePreferences();

  const localState = (localStorage && localStorage.getItem("preview")) ? JSON.parse(localStorage.getItem("preview")) : {};
  let initialData = localState; 

  const [preview, setPreview] = useReducer(reducer, initialData);

  useEffect(() => {
    if(preview_time !== null && (!initialData || initialData?.expires == null)) {
      // console.log('initial data is empty...');

      let initialData = {
        seconds: preview_time,
        frequency: preview_frequency,
      };
      
      setPreview({
        type: 'RESET',
        payload: initialData
      });
    }  
  }, [preview_time, setPreview])

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const localState = JSON.parse(localStorage.getItem("preview"));

      if(localState && localState.expires == null) {
        // console.log('localstate expires is null');
        setPreview({
          type: 'RESET',
          payload: {
            seconds: preview_time,
            frequency: preview_frequency
          }
        });
      }
      else if(preview && preview.expires && new Date(preview.expires) - new Date() < 0) {
        // console.log('localstate is set but is expires date is in past');
        setPreview({
          type: 'RESET',
          payload: {
            seconds: preview_time,
            frequency: preview_frequency
          }
        });
      }
      else if(preview) {
        // console.log('setting preview local storage');
        // console.log(preview);
        localStorage.setItem("preview", JSON.stringify(preview));
      }

      if(typeof preview.newsletter_filled === 'undefined') {
        setPreview({
          type: 'SET_NEWSLETTER',
          payload: false
        });
      }

      if(typeof preview.discount_modal === 'undefined') {
        setPreview({
          type: 'SET_DISCOUNT_MODAL',
          payload: {
            filled: false,
            show_32522: true,
            show_again_date: new Date()
          }
        });
      }

      if(preview?.discount_modal?.show_32522 === false) {
        setPreview({
          type: 'SET_DISCOUNT_MODAL',
          payload: {
            filled: false,
            show_32522: true,
            show_again_date: new Date()
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if(preview && typeof preview.seconds === 'number') {
      localStorage.setItem("preview", JSON.stringify(preview));
    }
  }, [preview]);

  const reduceSecond = () => {
    if(user || user.sub_status == 'active') {
      return;
    }

    if(preview && preview.seconds && preview.seconds > 0) {
      setPreview({
        type: 'REDUCE_SECOND',
      });
    }
  }

  const hasExpired = () => {
    let expired = false;
    if(user && user.sub_status == 'active') {
      expired = false;
    }
    else if(props?.tier_name == 'Tier 0') {
      expired = false;
    }
    else if(preview && preview.seconds <= 0) {
      expired = true;
    }
    else if(preview && preview.expires && new Date(preview.expires) - new Date() < 0) {
      expired = true;
    }

    return expired;
  }

  const expiresDays = () => {
    if(preview?.expires !== null) {
      const now = new Date();
      const difference = new Date(preview.expires) - now;
      const difference_days = difference / (1000 * 3600 * 24);
console.log(preview.expires)
      return (difference_days > 0) ? Math.round(difference_days) : 0;
    }
    else {
      return false;
    }
  }

  const expiresTime = () => {
    const seconds = preview_time || 600;

    return secondsToMinutes(seconds);
  }

  const setNewsletterFilled = () => {
    setPreview({
      type: 'SET_NEWSLETTER',
      payload: true
    });
  }

  const showDiscountModal = () => {
    let show = false;

    if(user && user.sub_status == 'active') {
      show = false;
    }
    else if(preview?.discount_modal && preview?.discount_modal?.show_32522) {
      show = true;
    }

    return show;
  }

  const setDiscountModalFilled = () => {
    setPreview({type: 'DISCOUNT_MODAL_SIGNUP'});
  }

  const discountModalClose = () => {
    return setPreview({type: 'DISCOUNT_MODAL_CLOSE'});
  }

  const value = {
    ...preview,
    setPreview,
    hasExpired,
    expiresDays,
    expiresTime,
    reduceSecond,
    setNewsletterFilled,
    setDiscountModalFilled,
    showDiscountModal,
    discountModalClose
  }

  return <PreviewContext.Provider value={value} {...props} />;
};

export const usePreview = () => {
  const context = useContext(PreviewContext);
  if (context === undefined) {
    throw new Error(`usePreview must be used within a PreviewContextProvider.`);
  }
  return context;
};