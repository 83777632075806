import { createContext, useContext } from 'react';
import useSWR from 'swr';
import { useIntercom } from 'react-use-intercom'

import { isofetch } from 'services/fetch';

const getProfile = async (url) => {
  const profile = await isofetch(url);

  if(profile.success) {
    return profile.response;
  }
  else {
    return {};
  }
}

export const ProfileContext = createContext();

export const ProfileContextProvider = (props) => {
  const { data: profile_data, mutate: profilesMutate, error } = useSWR(`/api/fm/profile`, getProfile, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  // const { data } = useSWR(`/api/fm/status`, getProfile, { revalidateOnFocus: true });
  // if(data) {
  //   console.log(data.sessions);
  // }

  const value = {
    profiles: profile_data && profile_data.profiles || [],
    current_profile: profile_data && profile_data.current_profile || {},
    profilesMutate,
    isLoadingProfiles: profile_data && !profile_data.profiles && !error,
  };

  // const { update } = useIntercom(); 
  // update({...(profile_data?.current_profile) ? {
  //   createdAt: profile_data.current_profile.created_at
  // } : {}});

  return <ProfileContext.Provider value={value} {...props} />;
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error(`useProfile must be used within a ProfileContextProvider.`);
  }
  return context;
};