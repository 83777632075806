import { useEffect } from 'react';
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic';
import Script from 'next/script';

import NextNprogress from 'nextjs-progressbar';

import { UserContextProvider} from 'services/user';
import { ProfileContextProvider} from 'services/profile';
import { PreferencesContextProvider} from 'services/preferences';
import { PreviewContextProvider} from 'services/preview';
import { IntercomProvider } from 'react-use-intercom';

import { GA_TRACKING_ID, GA_MARKETING_ID, FB_PIXEL_ID, pageview } from 'utils/pixels'

const AppCache = dynamic(() => import('services/cache'), { ssr: false })

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

import 'styles/globals.css'

import Seo from 'components/Seo';

function WBA({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeStart', handleRouteChange)
    };
  }, [router.events]);


  return (
    <>
      <Script 
        strategy="afterInteractive"          
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />  
      <Script 
        id="gtag-init"
        strategy="afterInteractive"        
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });

            gtag('config', '${GA_MARKETING_ID}', {
              page_path: window.location.pathname,
            });
          `
        }}
      />
      {process.env.NEXT_PUBLIC_NODE_ENV == 'production' &&
        <Script
          strategy="afterInteractive"
          id="fb-init"
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${FB_PIXEL_ID});
              fbq('track', 'PageView');
            `,
          }}
        />
      }
      <Seo {...pageProps?.seo} />
      <AppCache>
        <PreferencesContextProvider {...pageProps}>
          <IntercomProvider appId={INTERCOM_APP_ID} initializeDelay>
            <UserContextProvider {...pageProps}>
              <PreviewContextProvider {...pageProps}>
                <ProfileContextProvider {...pageProps}>
                  <NextNprogress
                    color="#fff"
                    startPosition={0.3}
                    stopDelayMs={200}
                    height={2}
                  />
                  <Component {...pageProps} />
                </ProfileContextProvider>
              </PreviewContextProvider>
            </UserContextProvider>
          </IntercomProvider>
        </PreferencesContextProvider>
      </AppCache>
    </>
  );
}

export default WBA;