export const slugify = (string) => {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

export const parseVideoURL = (url) => {
  function getParm(url, base) {
      var re = new RegExp("(\\?|&)" + base + "\\=([^&]*)(&|$)");
      var matches = url.match(re);
      if (matches) {
          return(matches[2]);
      } else {
          return("");
      }
  }

  var retVal = {};
  var matches;

  if (url.indexOf("youtube.com/watch") != -1) {
      retVal.provider = "youtube";
      retVal.id = getParm(url, "v");
  } else if (matches = url.match(/vimeo.com\/(\d+)/)) {
      retVal.provider = "vimeo";
      retVal.id = matches[1];
  }
  return(retVal);
}

export const secondsToString = ( seconds, short=false, showSeconds=true ) => {
  const hours_label = short ? 'hrs' : 'hours';
  const minutes_label = short ? 'mins' : 'minutes';
  const seconds_label = short ? 'secs' : 'seconds';

  var levels = [
    [Math.floor(((seconds % 31536000) % 86400) / 3600), hours_label],
    [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), minutes_label],
  ];

  if(showSeconds) {
      levels.push([(((seconds % 31536000) % 86400) % 3600) % 60, seconds_label]);
  }

  var returntext = '';

  for (var i = 0, max = levels.length; i < max; i++) {
      if ( levels[i][0] === 0 ) continue;
      returntext += ' ' + levels[i][0] + ' ' + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length-1): levels[i][1]);
  };
  return returntext.trim();
}

export const secondsToTimer = (timeInSeconds) => {
  let pad = function(num, size) { return ('000' + num).slice(size * -1); },
  time = parseFloat(timeInSeconds).toFixed(3),
  hours = Math.floor(time / 60 / 60),
  minutes = Math.floor(time / 60) % 60,
  seconds = Math.floor(time - minutes * 60),
  milliseconds = time.slice(-3);

  if(hours > 0) {
    return pad(hours, 1) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
  }
  else if(minutes > 0 && minutes >= 10 ) {
    return pad(minutes, 2) + ':' + pad(seconds, 2);
  }
  else if(minutes > 0) {
    return pad(minutes, 1) + ':' + pad(seconds, 2);
  }
  else {
    return pad(minutes, 1) + ':' + pad(seconds, 2);
  }
}

export const secondsToMinutes = (timeInSeconds) => {
  let pad = function(num, size) { return ('000' + num).slice(size * -1); },
  time = parseFloat(timeInSeconds).toFixed(3),
  minutes = Math.floor(time / 60) % 60;

  if(minutes > 0) {
    return pad(minutes, 1);
  }
}

export const capitalize = (str) => {
  let words = str.replace('-', ' ').split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(" ");
}

export const getRealId = (slug) => {
  const split_slug = slug.split('-');
  const id_segments = [split_slug[0], split_slug[1], split_slug[2], split_slug[3], split_slug[4]];
  const real_id = id_segments.join('-');

  return real_id;
}
