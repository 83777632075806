import { createContext, useContext } from 'react';
import useSWR from 'swr';

import { isofetch } from 'services/fetch';

const getPreferences = async (url) => {
  const data = await isofetch(url);

  if(data.success) {
    return data.response;
  }
  else {
    return {};
  }
}

export const PreferencesContext = createContext();

export const PreferencesContextProvider = (props) => {
  const { data, mutate, error } = useSWR(`/api/fm/preferences`, getPreferences, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const value = {
    ...data,
    mutate: mutate,
    isLoadingPreferences: !data && !error,
  };

  return <PreferencesContext.Provider value={value} {...props} />;
};

export const usePreferences = () => {
  const context = useContext(PreferencesContext);
  if (context === undefined) {
    throw new Error(`usePreferences must be used within a PreferencesContextProvider.`);
  }
  return context;
};