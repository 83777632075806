import { createContext, useContext } from 'react';
import useSWR from 'swr';
import { useRouter } from 'next/router'
import { useIntercom } from 'react-use-intercom'

import { isofetch } from 'services/fetch';

const getUser = async (url) => {
  const user = await isofetch(url);
  return user;
}

export const refreshSession = async () => {
  await isofetch(`/api/refresh`);
  // location.reload();
}

export const getFmId = async (data) => {
  const new_fm_id = await isofetch(`/api/fm/account`, 'POST', {
    id: data.sub,
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
  });
  if(new_fm_id.success) {
    location.reload();
  }
}

export const UserContext = createContext();

export const UserContextProvider = (props) => {
  const { data: user, mutate: userMutate, error } = useSWR(`/api/auth/me`, getUser, {
    revalidateOnFocus: false
  });

  const router = useRouter();
  const { refresh } = router.query;
  if(user && (user.fm_id == '' || user.fm_id == false)) {
    getFmId(user);
  }

  if((user && user.refresh) || refresh) {
    refreshSession();
  }

  if(user && user.is_new && user.sub_status == '') {
    // is new user, redirect to stripe
    // router.push('/api/auth/signup?plan=year&returnTo=/api/stripe/subscribe-redirect');
  }

  if(error) {
    if(router.pathname != '/account') {
      // router.push('/account');
    }
  }

  const value = {
    user: (user && user.email) ? user : false,
    userMutate,
    isLoading: !user && !error
  };

  const { boot } = useIntercom(); 
  boot({...(user) ? {
    name: user.name,
    email: user.email,
    userId: user.fm_id,
    userHash: user.hash
  } : {}});
  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`);
  }
  return context;
};