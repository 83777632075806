export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GAID;
export const GA_MARKETING_ID = process.env.NEXT_PUBLIC_GAID_MARKETING;
export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const pageview = (url) => {
  window && window.gtag && window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
  
  window && window.gtag && window.gtag('config', GA_MARKETING_ID, {
    page_path: url,
  })

  window && window.fbq && window.fbq('track', 'PageView');
}

const gaevent = (name, options) => {
  window && window.gtag && window.gtag('event', name, options);
}

const fbevent = (name, options) => {
  window && window.fbq && window.fbq('track', name, options);
}

export const trackAddToCart = (name, value, id) => {
  gaevent('add_to_cart', {
    currency: 'USD', 
    value: value,
    items: [{
      item_name: name,
      price: value
    }]
  });

  fbevent('AddToCart', {
    content_name: name,
    currency: 'USD', 
    value: value,
    content_ids: [id],
    content_type: 'product'
  });

  // console.log('tracking add to cart... ', {name, value, id});
}

export const trackSignup = () => {
  gaevent('signup');

  fbevent('Lead');
  // console.log('tracking signup... ');
}

export const trackPurchase = (name, value, id) => {
  gaevent('purchase', {
    currency: 'USD', 
    value: value,
    items: [{
      item_name: name
    }]
  });

  fbevent('Purchase', {
    content_name: name,
    currency: 'USD', 
    value: value,
    content_ids: [id],
    content_type: 'product'
  });

  // console.log('tracking purchase... ', {name, value, id});
}